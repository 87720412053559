import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Image } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "image"
    }}>{`Image`}</h1>
    <p>{`An image.`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Image src=\"/public/bysykkels.jpg\" alt=\"Docked bikes\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Image src="/public/bysykkels.jpg" alt="Docked bikes" mdxType="Image" />
    </Playground>
    <h2 {...{
      "id": "with-shadow-and-rounded-corners"
    }}>{`With shadow and rounded corners`}</h2>
    <p>{`For editorial images.`}</p>
    <Playground __position={1} __code={'<Image\n  src=\"/public/bysykkels.jpg\"\n  alt=\"Docked bikes\"\n  boxShadow=\"heavy\"\n  borderRadius=\"lg\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Image src="/public/bysykkels.jpg" alt="Docked bikes" boxShadow="heavy" borderRadius="lg" mdxType="Image" />
    </Playground>
    <h2 {...{
      "id": "round"
    }}>{`Round`}</h2>
    <p>{`For profile pictures and similar. Use square pictures.`}</p>
    <Playground __position={2} __code={'<Image\n  src=\"/public/bysykkels-square.jpg\"\n  alt=\"Docked bikes\"\n  height={100}\n  rounded\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Image,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Image src="/public/bysykkels-square.jpg" alt="Docked bikes" height={100} rounded mdxType="Image" />
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Image} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      